body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title{
  color: #fff !important;
  font-weight: 700;
  font-size: 35px;
}
.text-white h1{
  color: #d1d702;
}
@media  only screen and (max-width: 900px) {
  .content{
    margin-top: 100px !important;
    text-align: center;
  }
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 15px !important;
}  
.MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{
border-radius: 15px !important;
  
}
.e-btn{
  border-radius: 15px !important;
}
.MuiOutlinedInput-root{
  border-radius: 15px !important;
}
.item4-bg{
  background-color: #4f003b;
}